import { useState } from "react";

export function useForm(initalValues) {
    const [values, setValues] = useState(initalValues);

    return [
        values,
        (e) => {
            setValues({
                ...values,
                [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value, 
            })
        }
    ]
}