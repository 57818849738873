import './assets/css/App.css';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { FaClipboard } from 'react-icons/fa';
import { useForm } from './useForm';
import { getSpecialCharacter, getRandomCharacter } from './utils';

function App() {

  const [values, setValues] = useForm({
    length: 25,
    capital: true,
    small: true,
    number: true,
    symbol: true
  });
  const [result, setResult] = useState("");

  const fieldsArray = [
    {
      field: values.capital,
      getChar: () => getRandomCharacter(65, 90),

    },
    {
      field: values.small,
      getChar: () => getRandomCharacter(97, 122),
    },
    {
      field: values.number,
      getChar: () => getRandomCharacter(48, 57),
    },
    {
      field: values.symbol,
      getChar: () => getSpecialCharacter(),
    },
  ];

  const handleOnSubmit = (e) => {
    e.preventDefault();
    generatePassword();
  };

  const generatePassword = () => {
    let generatedPassword = "";
    const checkedFields = fieldsArray.filter(({field}) => field);
  
    for(let i=0; i < values.length; i++) {
      const index = Math.floor(Math.random() * checkedFields.length);
      const letter = checkedFields[index]?.getChar();

      if(letter) {
        generatedPassword += letter;
      } 
    }
    if(generatedPassword) {
      setResult(generatedPassword);
    } else {
      toast.error("Selecteer minimaal 1 optie");
    }
  };

  useEffect(() => {
    generatePassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClipboard = async () => {
    if(result) {
      const textarea = document.createElement('textarea');
      textarea.value = result;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      toast.success('Gekopieërd!');
    } else {
      toast.error("Geen wachtwoord");
    }
  }

  return (
    <section>
      <div className="container">
        <form id="pg-form" onSubmit={handleOnSubmit}>
          <div className="result">
            <input
              type="text"
              id="result"
              placeholder="Wachtwoord"
              readOnly
              value={result}
            />
            <div className="clipboard" onClick={handleClipboard} >
              <FaClipboard></FaClipboard>
            </div>
          </div>
          <div>
            <div className="field">
              <label htmlFor="length">Lengte</label>
              <input
                type="number"
                id="length"
                min={15}
                max={45}
                name="length"
                value={values.length}
                onChange={setValues}
              />
            </div>
            <div className="field">
              <label htmlFor="capital">Hoofdletters</label>
              <input
                type="checkbox"
                id="capital"
                name="capital"
                checked={values.capital}
                onChange={setValues}
              />
            </div>
            <div className="field">
              <label htmlFor="small">Kleine letters</label>
              <input
                type="checkbox"
                id="small"
                name="small"
                checked={values.small}
                onChange={setValues}
              />
            </div>
            <div className="field">
              <label htmlFor="number">Cijfers</label>
              <input
                type="checkbox"
                id="number" 
                name="number"
                checked={values.number}
                onChange={setValues}
              />
            </div>
            <div className="field">
              <label htmlFor="symbol">Symbolen</label>
              <input
                type="checkbox"
                id="symbol"
                name="symbol"
                checked={values.symbol}
                onChange={setValues}
              />
            </div>
          </div>
          <button type="submit">Genereer wachtwoord</button>
        </form>
      </div>
    </section>
  );
}

export default App;
